//------------------------------------------------------------------------------------

import '../../external.scss';

import {
	AddPhoto,
	Badge,
	Clear,
	PersonAdd,
} from '../../../../components/Icons/Icons';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { Visibility, VisibilityOff } from '../../../../components/Icons/Icons';
import baseUrl, { adminBaseUrl } from '../../../../scripts/baseUrl';
import { useEffect, useState } from 'react';

import Footer from '../../../../components/Footer/Footer';
import Loading from '../../../../components/Loading/Loading';
import Logo from '../../../../assets/logo.png';
import auth from '../../../../scripts/auth';
import axios from 'axios';
import displayRazorpay from '../../../../scripts/paymentGateway';
import { encryptData } from '../../../../scripts/encrypt';
import theme from '../../../../scripts/theme';
import { socket } from '../../../../App';

//------------------------------------------------------------------------------------

const openProfilePhotoInput = () => {
	const profilePhotoInput = document.getElementById('profile-photo-input');
	profilePhotoInput.click();
};

const openIdProofInput = () => {
	const idProofInput = document.getElementById('id-proof-input');
	idProofInput.click();
};

const openShopPhotoInput = () => {
	const shopPhotoInput = document.getElementById('shop-photo-input');
	shopPhotoInput.click();
};

const openSignaturePhotoInput = () => {
	const signaturePhotoInput = document.getElementById(
		'signature-photo-input'
	);
	signaturePhotoInput.click();
};

const openShopStampPhotoInput = () => {
	const shopStampPhotoInput = document.getElementById(
		'shop-stamp-photo-input'
	);
	shopStampPhotoInput.click();
};

//------------------------------------------------------------------------------------

const profilePhotoValidation = () => {
	const profilePhotoInput = document.getElementById('profile-photo-input');
	const addProfilePhoto = document.getElementById('add-profile-photo');
	if (profilePhotoInput.value.length !== 0) {
		addProfilePhoto?.parentElement?.classList.remove('error');
		return true;
	} else {
		addProfilePhoto?.parentElement?.classList.add('error');
		return false;
	}
};

const idProofValidation = () => {
	const idProofInput = document.getElementById('id-proof-input');
	const addIdProof = document.getElementById('add-id-proof');
	if (idProofInput.value.length !== 0) {
		addIdProof?.parentElement?.classList.remove('error');
		return true;
	} else {
		addIdProof?.parentElement?.classList.add('error');
		return false;
	}
};

const shopPhotoValidation = () => {
	const shopPhotoInput = document.getElementById('shop-photo-input');
	const addShopPhoto = document.getElementById('add-shop-photo');
	if (shopPhotoInput.value.length !== 0) {
		addShopPhoto?.parentElement?.classList.remove('error');
		return true;
	} else {
		addShopPhoto?.parentElement?.classList.add('error');
		return false;
	}
};

const signaturePhotoValidation = () => {
	const signaturePhotInput = document.getElementById('signature-photo-input');
	const addShopSignPhoto = document.getElementById('add-shop-sign-photo');
	if (signaturePhotInput.value.length !== 0) {
		addShopSignPhoto?.parentElement?.classList.remove('error');
		return true;
	} else {
		addShopSignPhoto?.parentElement?.classList.add('error');
		return false;
	}
};

const shopStampPhotoValidation = () => {
	const shopStampPhotoInput = document.getElementById(
		'shop-stamp-photo-input'
	);
	const addShopStampPhoto = document.getElementById('add-shop-stamp-photo');
	if (shopStampPhotoInput.value.length !== 0) {
		addShopStampPhoto?.parentElement?.classList.remove('error');
		return true;
	} else {
		addShopStampPhoto?.parentElement?.classList.add('error');
		return false;
	}
};

const nameValidation = () => {
	const name = document.getElementById('name');
	const nameHint = document.getElementById('name-hint');
	if (name.value.length === 0) {
		name.parentElement?.classList.add('error');
		nameHint.innerHTML = 'Please enter full name';
		return false;
	} else {
		name.parentElement?.classList.remove('error');
		nameHint.innerHTML = '&nbsp;';
		return true;
	}
};

const addressValidation = () => {
	const address = document.getElementById('address');
	const addressHint = document.getElementById('address-hint');
	if (address.value.length === 0) {
		address.parentElement?.classList.add('error');
		addressHint.innerHTML = 'Please enter address';
		return false;
	} else {
		address.parentElement?.classList.remove('error');
		addressHint.innerHTML = '&nbsp;';
		return true;
	}
};

const shopNameValidation = () => {
	const shopName = document.getElementById('shop-name');
	const shopNameHint = document.getElementById('shop-name-hint');
	if (shopName.value.length === 0) {
		shopName.parentElement?.classList.add('error');
		shopNameHint.innerHTML = 'Please enter stall name';
		return false;
	} else {
		shopName.parentElement?.classList.remove('error');
		shopNameHint.innerHTML = '&nbsp;';
		return true;
	}
};

const usernameValidation = () => {
	const username = document.getElementById('username');
	const usernameHint = document.getElementById('username-hint');
	if (username.value.length === 0) {
		username.parentElement?.classList.add('error');
		usernameHint.innerHTML = 'Please enter username';
		return false;
	} else if (username.value.length < 3 || username.value.length > 25) {
		username.parentElement?.classList.add('error');
		usernameHint.innerHTML =
			'Username must be between 3 and 25 characters.';
		return false;
	} else {
		username.parentElement?.classList.remove('error');
		usernameHint.innerHTML = '&nbsp;';
		return true;
	}
};

const emailValidation = () => {
	const email = document.getElementById('email');
	const emailHint = document.getElementById('email-hint');
	const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

	if (email.value.match(emailRegex)) {
		email.parentElement?.classList.remove('error');
		emailHint.innerHTML = '&nbsp;';
		return true;
	} else {
		email.parentElement?.classList.add('error');
		emailHint.innerHTML = 'Please enter valid email';
		return false;
	}
};

const mobileValidation = () => {
	const mobile = document.getElementById('mobile');
	const mobileHint = document.getElementById('mobile-hint');
	const mobileRegex = /^\d{10}$/;
	if (mobile.value.match(mobileRegex)) {
		mobile.parentElement?.classList.remove('error');
		mobileHint.innerHTML = '&nbsp;';
		return true;
	} else {
		mobile.parentElement?.classList.add('error');
		mobileHint.innerHTML = 'Please enter valid mobile';
		return false;
	}
};

const startingDateValidation = () => {
	const startingDate = document.getElementById('starting-date');
	if (startingDate.value.length !== 0) {
		startingDate.parentElement?.classList.remove('error');
		startingDate.parentElement?.parentElement?.classList.remove('error');
		return true;
	} else {
		startingDate.parentElement?.classList.add('error');
		startingDate.parentElement?.parentElement?.classList.add('error');
		return false;
	}
};

const endingDateValidation = () => {
	const endingDate = document.getElementById('ending-date');
	if (endingDate.value.length !== 0) {
		endingDate.parentElement?.classList.remove('error');
		endingDate.parentElement?.parentElement?.classList.remove('error');
		return true;
	} else {
		endingDate.parentElement?.classList.add('error');
		endingDate.parentElement?.parentElement?.classList.add('error');
		return false;
	}
};

const passwordValidation = () => {
	const password = document.getElementById('password');
	const passwordHint = document.getElementById('password-hint');
	const passwordRegex =
		/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/;
	if (password.value.match(passwordRegex)) {
		password.parentElement?.classList.remove('error');
		passwordHint.innerHTML = '&nbsp;';
		return true;
	} else {
		password.parentElement?.classList.add('error');
		passwordHint.innerHTML =
			'Please enter password between 8 to 15 characters which contain at least one lowercase letter, one uppercase letter, one numeric digit, and one special character.';
		return false;
	}
};


// Open LOA input
const openLoaFileInput = () => {
	const loaInput = document.getElementById('loa-upload-input');
	if (loaInput) {
		loaInput.click(); // Simulate click to open file input dialog
	}
};

// Validate LOA file input
const loaValidation = () => {
	const loaInput = document.getElementById('loa-upload-input');
	const addLoaFile = document.getElementById('add-loa-file');

	if (loaInput?.files.length !== 0) {
		addLoaFile?.parentElement?.classList.remove('error');
		return true;
	} else {
		addLoaFile?.parentElement?.classList.add('error');
		return false;
	}
};

// Display LOA file name or preview (if required)
const displayLoaFile = (event) => {
	const loaFile = event.target.files[0]; // Get the selected file
	if (loaFile) {
		// Ensure the file is a PDF
		if (loaFile.type === 'application/pdf') {
			// Set the file name in UI
			const fileNameElement = document.querySelector('.loa-file-name');
			if (fileNameElement) {
				fileNameElement.textContent = loaFile.name; // Display the file name
			}
		} else {
			alert('Please upload a valid PDF file.');
			removeLoaFile(); // Reset file input if invalid type
		}
	}
};

// Remove LOA file and reset the input and UI
const removeLoaFile = () => {
	const loaInput = document.getElementById('loa-upload-input');
	const fileNameElement = document.querySelector('.loa-file-name');
	const addLoaFile = document.getElementById('add-loa-file');

	// Clear the file input
	if (loaInput) {
		loaInput.value = ''; // Reset the file input field
	}

	// Clear the displayed file name
	if (fileNameElement) {
		fileNameElement.textContent = ''; // Reset the file name display
	}

	// Remove any error class (if previously added)
	if (addLoaFile) {
		addLoaFile.parentElement?.classList.remove('error');
	}
};




//------------------------------------------------------------------------------------

const Owner = () => {
	document.title = `Licensee Registration • Pune Division Catering Vendor Id`;

	const [stations, setStations] = useState(['']);

	const [owner, setOwner] = useState({
		photo: '',
		idPhoto: '',
		shopPhoto: '',
		signPhoto: '',
		stampPhoto: '',
		payScreenshot: '',
		fullname: '',
		shopAddress: '',
		station: stations[0],
		shopName: '',
		// username: '',
		stallType:'',
		email: '',
		mobile: '',
		category:'',
		startingDate: null,
		endingDate: null,
		reason: null,
		password: '',
		status: '0',
		approve: '0',
		vendorsCount: '0',
		payed: '0',
		addedByAdmin: '0',
	});

	const navigate = useNavigate();

	const [isRegisterButtonDisabled, setIsRegisterButtonDisabled] =
		useState(false);

	const [validRegistration, setValidRegistration] = useState(false);
	const [passwordVisibility, setPasswordVisibility] = useState(false);

	const [loader, setLoader] = useState(false);
	const [checkbox, setCheckbox] = useState('');

	//photo
	const [photoFile, setPhotoFile] = useState();
	const [photoFileName, setPhotoFileName] = useState('');

	//id proof
	const [idProofFile, setIdProofFile] = useState();
	const [idProofFileName, setIdProofFileName] = useState('');

	//shopPhoto
	const [shopPhotoFile, setShopPhotoFile] = useState();
	const [shopPhotoFileName, setShopPhotoFileName] = useState('');

	//signaturePhoto
	const [signaturePhotoFile, setSignaturePhotoFile] = useState();
	const [signaturePhotoFileName, setSignaturePhotoFileName] = useState('');

	//stampPhoto
	const [stampPhotoFile, setStampPhotoFile] = useState();
	const [stampPhotoFileName, setStampPhotoFileName] = useState('');

	const [loaFile,setLoaFile] = useState(null)

	//category 
	const [stallCategories,setStallCategories] = useState([])
	const [stallTypes,setStallTypes] = useState([])

useEffect(() => {
	// Fetch stall types from the API
	const fetchStallTypes = async () => {
		try {
			const response = await axios.get(`${adminBaseUrl}/stalltypes`); // Adjust the endpoint if necessary
			setStallTypes(response?.data);
		} catch (error) {
			console.error('Error fetching stall types:', error);
		}
	};

	fetchStallTypes();
}, []);

	const getStation = async () => {
		try {
			setLoader(true);
			const res = await axios.get(`${adminBaseUrl}/stations`);
			console.log("res dta "+ await res.data);
			setStations(await res?.data);
			setOwner((prev) => ({ ...prev, station: res.data[0]?.station }));
		} catch (error) {
			console.log(error);
		}
		setLoader(false);
	};

	const getCategories = async () => {
		try {
			setLoader(true);
			const res = await axios.get(`${adminBaseUrl}/categories`);
			console.log("res dta "+ await res.data);
			setStallCategories(await res?.data);
			setOwner((prev) => ({ ...prev, category: res.data[0]?.category }));
		} catch (error) {
			console.log(error);
		}
		setLoader(false);
	};

	// console.log(
	// 	"stations "+ stations
	// );

	useEffect(() => {
		getStation();
        getCategories()
		/* const body = document.getElementsByTagName('body')[0];
        const onEnter = document.getElementById('on-enter');
        body.addEventListener("keypress", function (event) {
            if (event.key === "Enter") {
                event.preventDefault();
                onEnter.click();
            }
        }); */

		//blocking some keys for name
		const name = document.getElementById('name');
		name.addEventListener('keypress', (event) => {
			var regex = new RegExp('^[a-zA-Z ]+$');
			var key = String.fromCharCode(
				!event.charCode ? event.which : event.charCode
			);
			if (!regex.test(key)) {
				event.preventDefault();
				return false;
			}
		});

		//blocking some keys for shop name
		const shopName = document.getElementById('shop-name');
		shopName.addEventListener('keypress', (event) => {
			var regex = new RegExp('^[a-zA-Z0-9./, ]+$');
			var key = String.fromCharCode(
				!event.charCode ? event.which : event.charCode
			);
			if (!regex.test(key)) {
				event.preventDefault();
				return false;
			}
		});

		//blocking some keys for username
		// const username = document.getElementById('username');
		// username.addEventListener('keypress', (event) => {
		//     var regex = new RegExp("^[a-zA-Z0-9]+$");
		//     var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
		//     if (!regex.test(key)) {
		//         event.preventDefault();
		//         return false;
		//     }
		// });

		//blocking some keys for email
		const email = document.getElementById('email');
		email.addEventListener('keypress', (event) => {
			var regex = new RegExp('^[a-zA-Z0-9.@]+$');
			var key = String.fromCharCode(
				!event.charCode ? event.which : event.charCode
			);
			if (!regex.test(key)) {
				event.preventDefault();
				return false;
			}
		});
	}, []);

	//profile photo
	const removeProfilePhoto = () => {
		setProfilePhotoSrc('');
		const profilePhotoInput = document.getElementById(
			'profile-photo-input'
		);
		profilePhotoInput.value = '';

		//photo
		setPhotoFile('');
		setPhotoFileName('');
	};

	const [profilePhotoSrc, setProfilePhotoSrc] = useState('');
	const displayProfilePhoto = (event) => {
		if (event.target.files[0].size < 1000000) {
			setProfilePhotoSrc(URL.createObjectURL(event.target.files[0]));

			//photo
			setPhotoFile(event.target.files[0]);
			setPhotoFileName(event.target.files[0].name);
		} else {
			alert('Image size should be less than 1MB.');
			removeProfilePhoto();
			profilePhotoValidation();
		}
	};

	//id proof
	const removeIdProof = () => {
		setIdProofSrc('');
		const IdProofInput = document.getElementById('id-proof-input');
		IdProofInput.value = '';

		//id
		setIdProofFile('');
		setIdProofFileName('');
	};

	const [idProofSrc, setIdProofSrc] = useState('');
	const displayIdProof = (event) => {
		if (event.target.files[0].size < 1000000) {
			setIdProofSrc(URL.createObjectURL(event.target.files[0]));

			//id
			setIdProofFile(event.target.files[0]);
			setIdProofFileName(event.target.files[0].name);
		} else {
			alert('Image size should be less than 1MB.');
			removeIdProof();
			idProofValidation();
		}
	};

	//shop photo
	const removeShopPhoto = () => {
		setShopPhotoSrc('');
		const shopPhotoInput = document.getElementById('shop-photo-input');
		shopPhotoInput.value = '';

		//shop
		setShopPhotoFile('');
		setShopPhotoFileName('');
	};

	const [shopPhotoSrc, setShopPhotoSrc] = useState('');
	const displayShopPhoto = (event) => {
		if (event.target.files[0].size < 1000000) {
			setShopPhotoSrc(URL.createObjectURL(event.target.files[0]));

			//shop
			setShopPhotoFile(event.target.files[0]);
			setShopPhotoFileName(event.target.files[0].name);
		} else {
			alert('Image size should be less than 1MB.');
			removeShopPhoto();
			shopPhotoValidation();
		}
	};

	// signature photo
	const removeSignaturePhoto = () => {
		setSignaturePhotoSrc('');
		const signaturePhotInput = document.getElementById(
			'signature-photo-input'
		);
		signaturePhotInput.value = '';

		setSignaturePhotoFile('');
		setSignaturePhotoFileName('');
	};

	const [signaturePhotoSrc, setSignaturePhotoSrc] = useState(null);
	const displaySignaturePhoto = (event) => {
		if (event.target.files[0].size < 1000000) {
			setSignaturePhotoSrc(URL.createObjectURL(event.target.files[0]));

			setSignaturePhotoFile(event.target.files[0]);
			setSignaturePhotoFileName(event.target.files[0].name);
		} else {
			alert('Image size should be less than 1MB.');
			removeSignaturePhoto();
			signaturePhotoValidation();
		}
	};

	// stamp photo
	const removeShopStampPhoto = () => {
		setShopStampPhotoSrc('');
		const shopStampPhotoInput = document.getElementById(
			'shop-stamp-photo-input'
		);
		shopStampPhotoInput.value = '';

		setStampPhotoFile('');
		setStampPhotoFileName('');
	};

	const [shopStampPhotoSrc, setShopStampPhotoSrc] = useState('');
	const displayShopStampPhoto = (event) => {
		if (event.target.files[0].size < 1000000) {
			setShopStampPhotoSrc(URL.createObjectURL(event.target.files[0]));

			setStampPhotoFile(event.target.files[0]);
			setStampPhotoFileName(event.target.files[0].name);
		} else {
			alert('Image size should be less than 1MB.');
			removeShopStampPhoto();
			shopStampPhotoValidation();
		}
	};

	const [loaFileSrc, setLoaFileSrc] = useState('');
const [loaFileName, setLoaFileName] = useState('');

const displayLoaFile = (event) => {
  const file = event.target.files[0];
  
  // Check if the file exists and ensure it is a PDF
  if (file && file.type === 'application/pdf') {
    if (file.size < 1000000) {
      setLoaFileSrc(file); // Store the file in state
      setLoaFileName(file.name); // Set the file name
	  setLoaFile(file)
    } else {
      alert('File size should be less than 1MB.');
      removeLoaFile(); // Reset file if validation fails
    }
  } else {
    alert('Please upload a valid PDF file.');
    removeLoaFile();
  }
};
	

	const addOwner = async () => {
		setIsRegisterButtonDisabled(true);
		let o = {...owner}
		console.log("this owner "+o);
		try {
			setLoader(true);

			let thisOwner = { ...owner };
			thisOwner.password = encryptData(thisOwner.password);


			console.log("this owner "+thisOwner);

			const res = await axios.post(`${baseUrl}/owners`, thisOwner);

			if (res.data.message === 'MOBILE_EXISTS') {
				alert(`Mobile number (${owner.mobile}) already exists.`);
			} else if (res.data.message === 'EMAIL_EXISTS') {
				alert(`Email (${owner.email}) already exists.`);
			} else {
				const ownerId = res.data.insertId;

				let tempAccessToken = res.data.tempAccessToken;

				if (res.data) {
					//photo
					const photo = new FormData();
					photo.append('image', photoFile);
					photo.append('fileName', photoFileName);

					try {
						const photoRes = await axios.put(
							`${baseUrl}/owners/photo/${ownerId}`,
							photo,
							{
								headers: {
									'access-token': tempAccessToken,
								},
							}
						);

						if (!auth(photoRes.data.message)) return;

						if (photoRes.data) {
						} else {
							alert('Oops, Something went wrong!');
						}
					} catch (err) {
						console.log(err);
					}

					//id proof
					const idProof = new FormData();
					idProof.append('image', idProofFile);
					idProof.append('fileName', idProofFileName);

					try {
						const idProofRes = await axios.put(
							`${baseUrl}/owners/idProof/${ownerId}`,
							idProof,
							{
								headers: {
									'access-token': tempAccessToken,
								},
							}
						);

						if (!auth(idProofRes.data.message)) return;

						if (idProofRes.data) {
						} else {
							alert('Oops, Something went wrong!');
						}
					} catch (err) {
						console.log(err);
					}

					//shop
					const shopPhotoF = new FormData();
					shopPhotoF.append('image', shopPhotoFile);
					shopPhotoF.append('fileName', shopPhotoFileName);

					try {
						const shopPhotoRes = await axios.put(
							`${baseUrl}/owners/shopPhoto/${ownerId}`,
							shopPhotoF,
							{
								headers: {
									'access-token': tempAccessToken,
								},
							}
						);

						if (!auth(shopPhotoRes.data.message)) return;

						if (shopPhotoRes.data) {
						} else {
							alert('Oops, Something went wrong!');
						}
					} catch (err) {
						console.log(err);
					}

					//signature
					// const signaturePhotoF = new FormData();
					// signaturePhotoF.append('image', signaturePhotoFile);
					// signaturePhotoF.append('fileName', signaturePhotoFileName);

					// try {
					// 	const signaturePhotoRes = await axios.put(
					// 		`${baseUrl}/owners/signaturePhoto/${ownerId}`,
					// 		signaturePhotoF,
					// 		{
					// 			headers: {
					// 				'access-token': tempAccessToken,
					// 			},
					// 		}
					// 	);

					// 	if (!auth(signaturePhotoRes.data.message)) return;

					// 	if (signaturePhotoRes.data) {
					// 	} else {
					// 		alert('Oops, Something went wrong!');
					// 	}
					// } catch (err) {
					// 	console.log(err);
					// }

                    // loa
					const signaturePhotoF = new FormData();
					signaturePhotoF.append('pdf', signaturePhotoFile);
					signaturePhotoF.append('fileName', signaturePhotoFileName);

					try {
						const signaturePhotoRes = await axios.put(
							`${baseUrl}/owners/signaturePhoto/${ownerId}`,
							signaturePhotoF,
							{
								headers: {
									'access-token': tempAccessToken,
								},
							}
						);

						if (!auth(signaturePhotoRes.data.message)) return;

						if (signaturePhotoRes.data) {
						} else {
							alert('Oops, Something went wrong!');
						}
					} catch (err) {
						console.log(err);
					}




					//stamp name
					const stampPhotoF = new FormData();
					stampPhotoF.append('image', stampPhotoFile);
					stampPhotoF.append('fileName', stampPhotoFileName);

					try {
						const stampPhotoRes = await axios.put(
							`${baseUrl}/owners/stampPhoto/${ownerId}`,
							stampPhotoF,
							{
								headers: {
									'access-token': tempAccessToken,
								},
							}
						);

						if (!auth(stampPhotoRes.data.message)) return;

						if (stampPhotoRes.data) {
						} else {
							alert('Oops, Something went wrong!');
						}
					} catch (err) {
						console.log(err);
					}


					// LOA (Letter of Authorization)
					const loaFileF = new FormData();
					loaFileF.append('image', loaFile); // Assuming `loaFile` is the file object for LOA
					loaFileF.append('fileName', loaFileName); // Assuming `loaFileName` contains the name of the LOA file

					try {
						const loaFileRes = await axios.put(
							`${baseUrl}/owners/loa/${ownerId}`, // Assuming this is the correct API endpoint for uploading the LOA
							loaFileF,
							{
								headers: {
									'access-token': tempAccessToken, // Passing the temporary access token
								},
							}
						);

						// Check if the response is successful using your `auth` function
						if (!auth(loaFileRes.data.message)) return;

						if (loaFileRes.data) {
							// Successfully uploaded LOA
						} else {
							alert('Oops, Something went wrong with LOA upload!');
						}
					} catch (err) {
						console.log(err);
					}



					socket.emit('updateOwner', {
						message: 'updateOwner',
					});

					navigate('/owner/payment', {
						replace: true,
						state: { ownerId: ownerId },
					});

					theme.set();

					alert('New owner has been registered, (You can login after approval).');
					setValidRegistration(true);
				} else {
					alert('Failed to registered.');
				}
			}
		} catch (err) {
			console.log(err);
		}

		setLoader(false);
		setIsRegisterButtonDisabled(false);
	};

	const handleChange = (e) => {
		setOwner((prev) => ({ ...prev, [e.target.name]: e.target.value }));
	};

	const showCheckbox = (value) => {
		setCheckbox(value);

		if (value) {
			setOwner((prev) => {
				return {
					...prev,
					reason: null,
				};
			});
		} else {
			setOwner((prev) => {
				return {
					...prev,
					startingDate: null,
					endingDate: null,
				};
			});
		}
	};

	//payment

	const validate = async () => {
		// try {
		//     setLoader(true);

		//     const res = await axios.get(`${baseUrl}/payment/values`);
		//     let amount = res.data.ownerCharges;

		//     await displayRazorpay(amount);
		// } catch (error) {
		//     console.log(error);
		// }
		// setLoader(false);

		profilePhotoValidation();
		idProofValidation();
		shopPhotoValidation();
		signaturePhotoValidation();
		shopStampPhotoValidation();
		nameValidation();
		addressValidation();
		shopNameValidation();
		// usernameValidation();
		emailValidation();
		mobileValidation();

		if (checkbox) {
			startingDateValidation();
			endingDateValidation();
		}
		passwordValidation();

		if (
			profilePhotoValidation() &&
			idProofValidation() &&
			shopPhotoValidation() &&
			signaturePhotoValidation() &&
			shopStampPhotoValidation() &&
			nameValidation() &&
			addressValidation() &&
			shopNameValidation() &&
			// usernameValidation() &&
			emailValidation() &&
			(checkbox
				? (startingDateValidation(), endingDateValidation())
				: true) &&
			mobileValidation() &&
			// startingDateValidation() &&
			// endingDateValidation() &&
			passwordValidation()
		) {
			addOwner();
		} else {
			setValidRegistration(false);
			return;
		}
	};

	const enterClick = (e) => {
		if (e.key === 'Enter' || e.keyCode === 13) {
			let button = document.getElementById('on-enter');
			button.click();
		}
	};

	return (
		<>
			<div className='external-screen'>
				<div className='frame'>
					<div className='outlined-card'>
						<form onKeyUp={(event) => enterClick(event)}>
							<div className='form-app-headline'>
								<div className='app-logo'>
									<img src={Logo} />
								</div>
								<div className='app-title title-small'>
									Pune Division Catering Vendor Id
								</div>
							</div>
							<div className='app-title headline-large'>
								Licensee Registration
							</div>
							<div className='images-container'>
								{/* Profile photo */}
								<div className='content-1'>
									<input
										type='file'
										id='profile-photo-input'
										name='photo'
										className='hidden'
										accept='image/*'
										onChange={(event) => {
											profilePhotoValidation();
											displayProfilePhoto(event);
										}}
									/>
									{profilePhotoSrc ? (
										<div className='profile-photo-container'>
											<img
												src={profilePhotoSrc}
												className='profile-photo'
												alt='profile'
											/>
											<button
												type='button'
												className='icon-button'
												onClick={removeProfilePhoto}
											>
												<Clear />
											</button>
										</div>
									) : (
										<div
											className='add-profile-photo'
											id='add-profile-photo'
											onClick={openProfilePhotoInput}
										>
											<PersonAdd />
										</div>
									)}
									<div className='bottom-label body-small'>
										Passport photo
									</div>
								</div>

								{/* ID proof */}
								<div className='content-2'>
									<input
										type='file'
										id='id-proof-input'
										name='idPhoto'
										className='hidden'
										accept='image/*'
										onChange={(event) => {
											idProofValidation();
											displayIdProof(event);
										}}
									/>

									{idProofSrc ? (
										<div className='id-proof-container'>
											<img
												src={idProofSrc}
												className='id-proof'
												alt='id proof'
											/>
											<button
												type='button'
												className='icon-button'
												onClick={removeIdProof}
											>
												<Clear />
											</button>
										</div>
									) : (
										<div
											className='add-id-proof'
											id='add-id-proof'
											onClick={openIdProofInput}
										>
											<Badge />
										</div>
									)}
									<div className='bottom-label body-small'>
										Id Proof
									</div>
								</div>
							</div>

							<div className='shop-images-container'>
								{/* Shop photo */}
								<div className='content-1'>
									<input
										type='file'
										id='shop-photo-input'
										name='shopPhoto'
										className='hidden'
										accept='image/*'
										onChange={(event) => {
											shopPhotoValidation();
											displayShopPhoto(event);
										}}
									/>
									{shopPhotoSrc ? (
										<div className='shop-photo-container'>
											<img
												src={shopPhotoSrc}
												className='shop-photo'
												alt='shop'
											/>
											<button
												type='button'
												className='icon-button'
												onClick={removeShopPhoto}
											>
												<Clear />
											</button>
										</div>
									) : (
										<div
											className='add-shop-photo'
											id='add-shop-photo'
											onClick={openShopPhotoInput}
										>
											<AddPhoto />
										</div>
									)}
									<div className='bottom-label body-small'>
										Licensee's Photo With Stall And Name
										Plate
									</div>
								</div>

								{/* Signature photo */}
								{/* <div className='content-2'>
									<input
										type='file'
										id='signature-photo-input'
										name='signPhoto'
										className='hidden'
										accept='image/*'
										onChange={(event) => {
											signaturePhotoValidation();
											displaySignaturePhoto(event);
										}}
									/>
									{signaturePhotoSrc ? (
										<div className='signature-photo-container'>
											<img
												src={signaturePhotoSrc}
												className='signature-photo'
												alt='Signature'
											/>
											<button
												type='button'
												className='icon-button'
												onClick={removeSignaturePhoto}
											>
												<Clear />
											</button>
										</div>
									) : (
										<div
											className='add-shop-sign-photo'
											id='add-shop-sign-photo'
											onClick={openSignaturePhotoInput}
										>
											<AddPhoto />
										</div>
									)}
									<div className='bottom-label body-small'>
										Upload LOA 
									</div>
								</div> */}

								{/* loa */}
								<div className='content-2'>
									<input
										type='file'
										id='signature-photo-input'
										name='signPhoto'
										className='hidden'
										accept='application/pdf'
										onChange={(event) => {
											signaturePhotoValidation();
											displaySignaturePhoto(event);
										}}
									/>
									{/* {signaturePhotoSrc ? (
										<div className='signature-photo-container'>
											<img
												src={signaturePhotoSrc}
												className='signature-photo'
												alt='Signature'
											/>
											<button
												type='button'
												className='icon-button'
												onClick={removeSignaturePhoto}
											>
												<Clear />
											</button>
										</div>
									) : (
										<div
											className='add-shop-sign-photo'
											id='add-shop-sign-photo'
											onClick={openSignaturePhotoInput}
										>
											<AddPhoto />
										</div>
									)} */}
									<div
											className='add-shop-sign-photo'
											id='add-shop-sign-photo'
											onClick={openSignaturePhotoInput}
										>
											<AddPhoto />
									</div>
									<div className='bottom-label body-small'>
										Upload LOA 
									</div>
								</div>




								{/* Stamp photo */}
								<div className='content-3'>
									<input
										type='file'
										id='shop-stamp-photo-input'
										name='stampPhoto'
										className='hidden'
										accept='image/*'
										onChange={(event) => {
											shopStampPhotoValidation();
											displayShopStampPhoto(event);
										}}
									/>
									{shopStampPhotoSrc ? (
										<div className='shop-stamp-container'>
											<img
												src={shopStampPhotoSrc}
												className='shop-stamp'
												alt='shop stamp'
											/>
											<button
												type='button'
												className='icon-button'
												onClick={removeShopStampPhoto}
											>
												<Clear />
											</button>
										</div>
									) : (
										<div
											className='add-shop-stamp-photo'
											id='add-shop-stamp-photo'
											onClick={openShopStampPhotoInput}
										>
											<AddPhoto />
										</div>
									)}
									<div className='bottom-label body-small'>
										Stall's Stamp and Signature Photo
									</div>
								</div>

								{/* LOA (Letter of Authorization) upload */}
								{/* <div className='content-4'>
									<input
										type='file'
										id='loa-upload-input'
										name='loaDocument'
										className='hidden'
										accept='application/pdf'
										onChange={(event) => {
											loaValidation();
											displayLoaFile(event);
										}}
									/>
									{loaFileSrc ? (
										<div className='loa-file-container'>
											<p className='loa-file-name'>{loaFileSrc.name}</p>
											<button type='button' className='icon-button' onClick={removeLoaFile}>
												<Clear />
											</button>
										</div>
									) : (
										<div className='add-loa-file' id='add-loa-file' onClick={openLoaFileInput}>
											<AddPhoto />
										</div>
									)}
									<div className='bottom-label body-small'>
										Upload LOA (Letter of Authorization)
									</div>
								</div> */}


							</div>

							<div className='label label-medium'>&nbsp;</div>

							{/* Full name */}
							<div className='label label-medium'>
								Enter Your Name As Per Aadhaar Card
							</div>
							<div className='input-container'>
								<input
									type='text'
									id='name'
									className='inputfield uppercase'
									name='fullname'
									placeholder='Full name'
									onBlur={nameValidation}
									onChange={handleChange}
								/>
								<div
									id='name-hint'
									className='input-hints'
								>
									&nbsp;
								</div>
							</div>

							{/* Shop name */}
							<div className='label label-medium'>
								Enter Your Stall Name
							</div>
							<div className='input-container'>
								<input
									type='text'
									id='shop-name'
									className='inputfield uppercase'
									name='shopName'
									placeholder='Stall name'
									onBlur={shopNameValidation}
									onChange={handleChange}
								/>
								<div
									id='shop-name-hint'
									className='input-hints'
								>
									&nbsp;
								</div>
							</div>

						    {/* stall type */}
							<div className='label label-medium'>
								Enter Your Stall Type
							</div>
							<div className='input-container'>
								{/* <input
									type='text'
									id='stall-type'
									className='inputfield uppercase'
									name='stallType'
									placeholder='Stall Type'
									onBlur={shopNameValidation}
									onChange={handleChange}
								/> */}
								 <select
								id="stall-type"
								className="inputfield uppercase"
								name="stallType"
								onBlur={shopNameValidation}
								// value={selectedStallType}
								onChange={handleChange}
                				// onChange={(e) => setSelectedStallType(e.target.value)}
								defaultValue="" // sets the placeholder as the default option
							>
								<option value="" disabled>
									Select Stall Type
								</option>
								{stallTypes?.length>0 && stallTypes?.map((stall) => (
									<option key={stall?.id} value={stall?.type}>
										{stall?.type}
									</option>
								))}
							</select>
								<div
									id='shop-name-hint'
									className='input-hints'
								>
									&nbsp;
								</div>
							</div>

							{/* Shop address */}
							<div className='label label-medium'>
								Enter Your Stall Location
							</div>
							<div className='input-container'>
								<textarea
									rows={2}
									id='address'
									className='inputarea uppercase'
									name='shopAddress'
									placeholder='Stall location'
									onBlur={addressValidation}
									onChange={handleChange}
									maxLength={1000}
								/>
								<div
									id='address-hint'
									className='input-hints'
								>
									&nbsp;
								</div>
							</div>

							{/* Station */}
							<div className='label label-medium'>
								Enter station
							</div>
							<div className='input-container'>
								<select
									id='station'
									className='inputfield'
									name='station'
									onChange={handleChange}
								>
									{ stations?.length>0 && stations?.map((station, index) => {
										return (
											<option
												key={index}
												value={station?.station}
											>
												{station?.station}
											</option>
										);
									})}
								</select>
								<div
									id='station-hint'
									className='input-hints'
								>
									&nbsp;
								</div>
							</div>

							<div className='label label-medium'>
								Enter Stall Category
								</div>
								<div className='input-container'>
								<select
									id='category'
									className='inputfield'
									name='category'
									onChange={handleChange}
								>
									{stallCategories?.length > 0 && stallCategories?.map((category, index) => {
									return (
										<option
										key={index}
										value={category?.category}
										>
										{category?.category}
										</option>
									);
									})}
								</select>
								<div
									id='stall-category-hint'
									className='input-hints'
								>
									&nbsp;
								</div>
								</div>


							{/* Username */}
							{/* <div className='label label-medium'>Enter your username</div>
                            <div className="input-container">
                                <input type="text" id='username' className='inputfield' name='username' placeholder='Username' onBlur={usernameValidation} onChange={handleChange} />
                                <div id='username-hint' className="input-hints">&nbsp;</div>
                            </div> */}

							{/* Email */}
							<div className='label label-medium'>
								Enter Your Email Id
							</div>
							<div className='input-container'>
								<input
									type='email'
									id='email'
									className='inputfield'
									name='email'
									placeholder='Email'
									maxLength={200}
									onBlur={emailValidation}
									onChange={handleChange}
								/>
								<div
									id='email-hint'
									className='input-hints'
								>
									&nbsp;
								</div>
							</div>

							{/* Mobile number */}
							<div className='label label-medium'>
								Enter Your Mobile Number
							</div>
							<div className='input-container'>
								<input
									type='number'
									id='mobile'
									className='inputfield'
									name='mobile'
									placeholder='Mobile no.'
									maxLength={10}
									onBlur={mobileValidation}
									onChange={handleChange}
								/>
								<div
									id='mobile-hint'
									className='input-hints'
								>
									&nbsp;
								</div>
							</div>

							<div className='question-container'>
								{/* <input type='checkbox' checked={checkbox} /> */}
								<div className='question body-medium'>
									Does your stall have validity?
								</div>

								<div className='checkbox-options-container'>
									<div className='checkbox-option'>
										<input
											type='radio'
											name='checkbox'
											id='yes-checkbox'
											onClick={() => showCheckbox(true)}
										/>
										<label
											htmlFor='yes-checkbox'
											className='checkbox-label label-medium'
										>
											Yes
										</label>
									</div>
									<div className='checkbox-option'>
										<input
											type='radio'
											name='checkbox'
											id='no-checkbox'
											onClick={() => showCheckbox(false)}
										/>
										<label
											htmlFor='no-checkbox'
											className='checkbox-label label-medium'
										>
											No
										</label>
									</div>
								</div>
							</div>
							<br></br>

							{checkbox === true && (
								<div className='two-inputs-container'>
									{/* Starting date */}
									<div className='date-input'>
										<div className='label label-medium'>
											Stall Starting Date
										</div>
										<div className='input-container'>
											<input
												type='date'
												id='starting-date'
												name='startingDate'
												className='inputfield'
												placeholder='Starting date'
												onBlur={startingDateValidation}
												onChange={handleChange}
											/>
										</div>
										<div className='bottom-label body-small'></div>
									</div>

									{/* Ending date */}
									<div className='date-input'>
										<div className='label label-medium'>
											Validity Date
										</div>
										<div className='input-container'>
											<input
												type='date'
												id='ending-date'
												name='endingDate'
												className='inputfield'
												placeholder='Ending date'
												onBlur={endingDateValidation}
												onChange={handleChange}
											/>
											{/* <div className="bottom-label body-small">Ending date</div> */}
										</div>
									</div>
								</div>
							)}

							{checkbox === false && (
								<>
									<div className='label label-medium'>
										Reason for not having stall validity.
									</div>
									<div className='input-container'>
										<textarea
											className='inputarea'
											placeholder='Reason'
											name='reason'
											onChange={handleChange}
										/>
										<div
											id='address-hint'
											className='input-hints'
										>
											&nbsp;
										</div>
									</div>
								</>
							)}

							{/* Password */}
							<div className='label label-medium'>
								Create Password
							</div>
							{/* <div className="input-container">
                                <input type="password" id='password' className='inputfield' name='password' placeholder='Password' maxLength={200} onBlur={passwordValidation} onChange={handleChange} />
                                <div id='password-hint' className="input-hints">&nbsp;</div>
                            </div> */}
							<div className='input-container password-inputfield'>
								<input
									type={
										passwordVisibility ? 'text' : 'password'
									}
									id='password'
									name='password'
									className='inputfield'
									placeholder='Password'
									onChange={handleChange}
								/>
								<div
									id='password-hint'
									className='input-hints'
								>
									&nbsp;
								</div>
								<div className='password-visibility'>
									<button
										type='button'
										className='icon-button'
										onClick={() =>
											setPasswordVisibility(
												(prev) => !prev
											)
										}
									>
										{passwordVisibility ? (
											<VisibilityOff />
										) : (
											<Visibility />
										)}
									</button>
								</div>
							</div>

							<div className='bottom-buttons'>
								<Link to='/login'>
									<button
										type='button'
										className='text-button'
										disabled={isRegisterButtonDisabled}
									>
										Already have an account? Login here
									</button>
								</Link>
								<button
									type='button'
									id='on-enter'
									className='filled-button'
									onClick={validate}
									disabled={isRegisterButtonDisabled}
								>
									Register
								</button>
							</div>
						</form>
					</div>
				</div>
			</div>
			<Footer />

			{loader && <Loading />}
			{/* {validRegistration && <Navigate replace to="/owner/payment" />} */}
		</>
	);
};

export default Owner;

//------------------------------------------------------------------------------------
